import { render, staticRenderFns } from "./PendingProfilesView.vue?vue&type=template&id=691bf440&scoped=true&"
import script from "./PendingProfilesView.vue?vue&type=script&lang=ts&"
export * from "./PendingProfilesView.vue?vue&type=script&lang=ts&"
import style0 from "./PendingProfilesView.vue?vue&type=style&index=0&id=691bf440&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691bf440",
  null
  
)

export default component.exports