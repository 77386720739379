
































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { Getter } from 'vuex-class';
import { DocumentReference } from '@firebase/firestore-types';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { CompanyProfile, Country, Nullable } from '@/app/shared/utilities/static-types';
import namespace from '@/app/shared/store/namespace';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import CompanyRegViewer from '@/app/shared/components/CompanyRegViewer.vue';
import { getCountriesAction } from '@globalActions/DevicesActions';
import PopupMessage from '@/app/shared/components/PopupMessage.vue';

@Component({
  components: {
    FormComponent,
    CompanyRegViewer,
    PopupMessage,
  },
})
export default class PendingProfilesView extends Vue {
  @Getter('getCurrentCompanyProfileRef', {
    namespace: namespace.CompanyProfileModule,
  })
  public currentRef!: DocumentReference;

  public companyName = '';
  public companyRegistryName = '';

  public companyRegistrationNumber = '';

  public companyRegCertLink: Nullable<string> = null;
  public companyRegCertType: Nullable<string> = null;

  public selectedCountry: Nullable<Country> = null;
  public countryArray: Country[] = [];
  public get countries(): Country[] {
    return this.countryArray!.map(
      (country) =>
        ({
          ...country,
          TEXT: this.$t(`countries.${country.DISPLAY_NAME}`).toString(),
        } as Country),
    );
  }

  public companyAddress = '';

  public productModel: string[] = [];

  public vatNumber = '';

  public selectedCompanySize = '';

  public systemProfilesRoute = AdminRoutes.PENDING_PROFILES;

  public comments = '';
  public commentsErrors: string | string[] = [];

  public rejected = true;

  public showConfirmationMessage: (() => Promise<any>) | null = null;

  public async loadProfileInfo() {
    if (!this.currentRef) {
      throw new Error('Error loading Profile.');
    }
    this.loadCountries();

    const profileSnap = await this.currentRef.get();
    const profile = profileSnap.data() as CompanyProfile;
    this.companyName = profile.COMPANY_NAME;
    this.companyRegistryName = profile.COMPANY_REGISTRY_NAME;
    this.companyRegistrationNumber = profile.COMPANY_REGISTRATION_NUMBER;
    this.companyRegCertLink =
      profile.COMPANY_REGISTRATION_CERTIFICATE.STATIC_LINK;
    this.companyRegCertType =
      profile.COMPANY_REGISTRATION_CERTIFICATE.CONTENT_TYPE;
    this.selectedCountry = profile.HEADQUARTER_COUNTRY;
    this.companyAddress = profile.COMPANY_ADDRESS;
    this.productModel = profile.COMPANY_PRODUCTS;
    this.vatNumber = profile.COMPANY_VAT!;
    this.selectedCompanySize = profile.COMPANY_SIZE!;
  }

  public loadCountries() {
    this.countryArray = getCountriesAction();
  }

  public async updateProfile() {
    if (this.rejected) {
      await this.rejectProfileAsAdmin();
    } else {
      await this.approveProfileAsAdmin();
    }
  }

  public async approveProfileAsAdmin() {
    try {
      await this.showConfirmationMessage!();
    } catch (err) {
      throw new Error('Profile approval not confirmed');
    }

    await this.currentRef.update({
      COMMENTS: this.comments,
      STATUS: SYSTEM_STATUS.APPROVED,
    } as CompanyProfile);
  }

  public async rejectProfileAsAdmin() {
    if (!this.comments) {
      this.commentsErrors = 'Required for rejection.';
      throw new Error('You must add comments in case of rejection.');
    }
    await this.currentRef.update({
      COMMENTS: this.comments,
      STATUS: SYSTEM_STATUS.REQUIRES_ACTION,
    } as CompanyProfile);
  }

  @Watch('comments')
  public onCommentsChanged(val: string, oldVal: string) {
    if (this.comments) {
      this.commentsErrors = [];
    }
  }

  @Watch('$root.$i18n.locale')
  public onChangeLocale(val: string, oldVal: string) {
    const prevSelectedCountry = this.selectedCountry;

    this.countryArray = getCountriesAction();

    if (prevSelectedCountry) {
      this.selectedCountry = this.countries.find(
        (country) => country.GEONAME_ID === prevSelectedCountry.GEONAME_ID,
      )!;
    }
  }
}
